(function ($) {
    class SlickCarousels {
        constructor() {
            this.initiateCarousel();
        }
        initiateCarousel() {
            $('#testimonial-slider').slick({
                row: 1,
                arrows: false,
                slidesToShow: 4,
                autoplay: true,
                autoplaySpeed: 2000,
                infinite: true,
                pauseOnFocus: false,
                pauseOnHover: false,
                slidesToScroll: 1,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 4,
                            infinite: true
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });
            $('.hero-slider').slick({
                arrows: false,
                slidesToShow: 1,
                autoplay: true,
                autoplaySpeed: 6000,
                infinite: true,
                pauseOnFocus: false,
                pauseOnHover: false,
                slidesToScroll: 1
            });
            $('.case-studies__prev').click(function () {
                $('.case-studies-carousel').slick('slickPrev');
            });
            $('.case-studies__next').click(function () {
                $('.case-studies-carousel').slick('slickNext');
            });
            $('.hero-prev').click(function () {
                $('.hero-slider').slick('slickPrev');
            });
            $('.hero-next').click(function () {
                $('.hero-slider').slick('slickNext');
            });

        }
    }
    new SlickCarousels();

})(jQuery);